import { useCallback, useEffect, useMemo, useState } from "react"

import { CssBaseline, theme } from "@suraasa/placebo-ui-legacy"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import { ThemeProvider } from "react-jss"

import api from "api"
import { Registration } from "api/resources/registrationForm/types"
import { GlobalContext } from "components/GlobalContext"
import GlobalSignInLoading from "components/GlobalSignInLoading"
import MasterclassPopup from "components/masterclasses/MasterclassPopup"
// import ItoPopup from "components/olympiad/ItoPopup"
// import OlympiadFinishRegistrationNotification from "components/olympiad/OlympiadFinishRegistrationNotification"
// import OlympiadLearnMoreNotification from "components/olympiad/OlympiadLearnMoreNotification"
import ErrorBoundary from "components/shared/ErrorBoundary"
import GlobalComponentWrapper from "components/shared/GlobalComponentWrapper"
import NetworkStatusLogging from "components/shared/NetworkStatusLogging"
import NoSSR from "components/shared/NoSSR"
import { getAuthInfo } from "utils/auth"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { GA, GA_EVENTS } from "utils/googleAnalytics"
import { trackUser } from "utils/helpers"
import useAutoLogin from "utils/hooks/useAutoLogin"
import useDetectCountry from "utils/hooks/useDetectCountry"
import useIframeMessagingAuth from "utils/hooks/useIframeMessagingAuth"
import useReferrerTracking from "utils/hooks/useReferrerTracking"
import ToastProvider from "utils/toast/ToastProvider"
import { trackingService } from "utils/tracking"
// import { trackingService } from "utils/tracking"
import { webengage } from "utils/webengage"

import "utils/polyfills"

import "styles/global.css"

const IS_LOCAL = process.env.NODE_ENV === "development"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MyApp({ Component, pageProps, router: appRouter }: AppProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [itoDetails, setItoDetails] = useState<
    Registration | null | undefined
  >()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        /**
         * We don't want failed requests to be retried by default
         * We can enable this on a per-API basis
         */
        retry: false,
      },
    },
  })

  useReferrerTracking()

  const router = useRouter()

  const country = useDetectCountry()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleRouteChange = (route?: string) => {
      trackingService.trackEvent(
        "PAGE_VIEW",
        {
          url: window.location.href,
        },
        ["WebEngage"]
      )
    }

    handleRouteChange()

    appRouter.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      appRouter.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [appRouter, isLoggedIn])

  const { loading: authLoading } = useAutoLogin({
    onLogin: () => {
      console.info("> Logged in")
      setIsLoggedIn(true)
    },
  })
  useIframeMessagingAuth({
    onLogin: () => {
      console.info("> Logged in")
      setIsLoggedIn(true)
    },
    onLogout: () => {
      console.info("> Logged out")
      setIsLoggedIn(false)
    },
  })
  useEffect(() => {
    if (router.isReady) {
      if (router.query.referral) {
        const parseParam = (queryParam: typeof router.query[number]) =>
          Array.isArray(queryParam) ? queryParam[0] : queryParam ?? ""

        sessionStorage.setItem(
          BROWSER_STORAGE_KEYS.itoReferralCode,
          parseParam(router.query.referral)
        )
      }
    }
  }, [router])

  useEffect(() => {
    const style = document.getElementById("jss-server-side")

    if (style && style.parentNode) {
      style.parentNode.removeChild(style)
    }
  }, [])

  useEffect(() => {
    setIsLoggedIn(Boolean(getAuthInfo()))
  }, [])

  // Trackers
  useEffect(() => {
    GA.init()

    const authInfo = getAuthInfo()
    if (authInfo) {
      trackUser(authInfo)
    }

    const trackEvent = (id: string, payload: object) => {
      // console.info("> Tracking Event", id, payload)
      GA.trackEvent(GA_EVENTS.click, {
        identifier: id,
        ...payload,
      })
    }

    const listener = (e: any) => {
      const trackAttributeName = "data-trackingid"
      let breakFlag = false

      if (!e.path) return

      for (let i = 0; i < e.path.length; i += 1) {
        const el = e.path[i]

        if ("hasAttributes" in el && el.hasAttributes()) {
          const params: Record<string, any> = {}
          const id: string | undefined =
            el.attributes[trackAttributeName]?.value

          if (id) {
            breakFlag = true

            for (const attr of el.attributes) {
              if (attr.name === trackAttributeName) {
                // We don't want to add trackingid in params. It is already catered
                continue
              }

              if (attr.name.startsWith("data-")) {
                params[attr.name.replace("data-", "")] = attr.value
              }
            }
            trackEvent(id, params)
          }
        }

        if (breakFlag) break
      }
    }
    window.addEventListener("click", listener)

    return () => {
      window.removeEventListener("click", listener)
    }
  }, [])

  useEffect(() => {
    const WEBENGAGE_KEY = process.env.NEXT_PUBLIC_WEBENGAGE_LICENSE_CODE

    if (WEBENGAGE_KEY) {
      webengage?.init(WEBENGAGE_KEY)
      webengage?.options("safariWebPush", true)
      if (IS_LOCAL) {
        webengage?.debug(true)
      }
    }

    if (WEBENGAGE_KEY && !webengage) {
      console.log("WebEngage was not available")
    }
  }, [])

  const refreshItoDetails = useCallback(async () => {
    const res = await api.registrationForm.registeredUser.get({})
    if (res.isSuccessful) {
      setItoDetails(res.data)
      return res.data
    }

    setItoDetails(null)
    return null
  }, [])

  const contextValue = useMemo(
    () => ({
      itoDetails,
      setItoDetails: (data: Partial<Registration>) => {
        setItoDetails(prevState => {
          if (!prevState) return null

          return {
            ...prevState,
            ...data,
          }
        })
      },
      refreshItoDetails,
      isLoggedIn,
      setIsLoggedIn,
      currentCountry: country,
    }),
    [itoDetails, isLoggedIn, country, refreshItoDetails]
  )

  useEffect(() => {
    refreshItoDetails()
  }, [refreshItoDetails])

  // const itoRegistrationInProgress = () => {
  //   if (!itoDetails) return false

  //   if (
  //     itoDetails.registrationFeePaid &&
  //     !itoDetails.registeredUserDetail?.educationLevel
  //   )
  //     return true
  // }

  // const isITOPage = router.pathname.includes("international-teachers-olympiad")
  // const isTTMPage = router.pathname.includes("talk-to-a-mentor")

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContext.Provider value={contextValue}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <CssBaseline />

            <NoSSR>
              <NetworkStatusLogging />
            </NoSSR>

            <GlobalComponentWrapper>
              {/* <ItoPopup /> */}
              {/* {!isStorageSupported && <SessionStorageBanner />} */}
              {/* {itoDetails === null && !isITOPage && !isTTMPage && (
              <OlympiadLearnMoreNotification />
            )} */}
              {/* {itoRegistrationInProgress() && !isITOPage && !isTTMPage && (
              <OlympiadFinishRegistrationNotification />
            )} */}
              <MasterclassPopup />
            </GlobalComponentWrapper>
            {authLoading ? (
              <GlobalSignInLoading />
            ) : (
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            )}
          </ToastProvider>
        </ThemeProvider>
      </GlobalContext.Provider>
    </QueryClientProvider>
  )
}

export default MyApp
