import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { BROWSER_STORAGE_KEYS } from "utils/constants"

import { RetrieveForm, Session, StartForm } from "./types"
import { urls } from "./urls"

const headersWithJwt = (options?: Config["headers"]) => {
  const token = sessionStorage.getItem(BROWSER_STORAGE_KEYS.mercuryJwtBearer)

  if (token) {
    options = {
      ...options,
      Authorization: `jwt_bearer ${token}`,
    }
  }
  return options
}

export default {
  start: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<StartForm>> => {
    try {
      const res = await axios.post(urls.form(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async (options: Config): Promise<APIResponse<RetrieveForm>> => {
    try {
      const res = await axios.get(urls.form(), {
        ...options,
        headers: headersWithJwt(options.headers),
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  saveResponse: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(urls.form(), data, {
        ...options,
        headers: headersWithJwt(options.headers),
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  scheduledSessions: async ({
    ...options
  }: Config): Promise<APIResponse<Session>> => {
    try {
      const res = await axios.get(urls.scheduledSessions(), {
        headers: headersWithJwt(options.headers),
        ...options,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  updateUserLead: async ({
    data,
    params,
    ...options
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(urls.userLead(), data, {
        ...options,
        params,
        headers: headersWithJwt(options.headers),
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
