// eslint-disable-next-line import/no-cycle
import { validateUrls } from "api/types"
// eslint-disable-next-line import/no-cycle
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getPolarisURL = getServiceURL(ServicePrefix.polaris)
const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  auth: {
    refreshToken: () => getSolisURL(`/v1/auth/refresh-token/`),
    generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),

    verifyAccessToken: () => getSolisURL("/v1/auth/access-tokens/verify/"),
  },
  closedBeta: {
    overview: () => getSolisURL("/v1/closed-beta/jobs/overview/"),
    signup: () => getSolisURL("/v1/closed-beta/users/"),
    resendVerificationEmail: () =>
      getSolisURL("/v1/closed-beta/users/resend-email/"),
  },
  profile: {
    markAsComplete: () => "/jobs/users/marketing-form/complete/",
  },
  stepsOverview: {
    list: () => "/jobs/users/marketing-form/overview/",
  },

  config: {
    attributes: () => getSolisURL(`/v1/users/attributes/`),
    preferences: () => getPolarisURL(`/v1/users/settings/`),
  },
})
