import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { getFingerprint } from "utils/fingerprint"

import {
  ImportantDates,
  NumberOfRegistrations,
  RegisteredUser,
  Registration,
  RegistrationUserDetail,
  SchoolDetail,
  VerifyRegisteredUser,
} from "./types"
import { urls } from "./urls"

const headersWithDeviceAndPlatform = async (options?: Config["headers"]) => {
  options = {
    ...options,
    "device-id": await getFingerprint(),
    platform: "suraasa-web",
  }
  return options
}

export default {
  registeredUser: {
    count: async (): Promise<APIResponse<NumberOfRegistrations>> => {
      try {
        const res = await axios.get(urls.registeredUser.count())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    get: async ({ options }: Config): Promise<APIResponse<Registration>> => {
      try {
        const res = await axios.get(urls.registration.details(), {
          // @ts-ignore Custom property
          preventRefreshRedirect: options?.preventRefreshRedirect,
        })
        // TODO: Only for testing
        // res.data.data.registrationFeePaid = true
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
    getById: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<
        Pick<Registration, "fullName" | "email"> & {
          referrerCode: string | null
          phoneNumber: {
            code: string
            number: string
          }
        }
      >
    > => {
      try {
        const res = await axios.get(urls.registration.getById(urlParams.id), {
          // @ts-ignore Custom property
          preventRefreshRedirect: true,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
    create: async ({ data }: Config): Promise<APIResponse<RegisteredUser>> => {
      try {
        const res = await axios.post(urls.registeredUser.base(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    verify: async ({
      data,
    }: Config): Promise<
      APIResponse<
        | VerifyRegisteredUser
        | {
            token: string
          }
      >
    > => {
      try {
        const res = await axios.post(urls.registeredUser.verify(), data, {
          headers: await headersWithDeviceAndPlatform(),
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    login: async ({
      urlParams,
      data,
    }: ConfigWithURLParams<"uuid">): Promise<
      APIResponse<VerifyRegisteredUser>
    > => {
      try {
        const res = await axios.post(
          urls.registeredUser.login(urlParams.uuid),
          data,
          {
            headers: await headersWithDeviceAndPlatform(),
          }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  registration: {
    details: async ({
      data,
    }: Config): Promise<APIResponse<RegistrationUserDetail>> => {
      try {
        const res = await axios.post(urls.registration.details(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
    update: async ({
      data,
    }: Config): Promise<APIResponse<RegistrationUserDetail>> => {
      try {
        const res = await axios.put(urls.registration.details(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
  },
  olympiadSchool: {
    list: async ({ params }: Config): Promise<APIResponse<SchoolDetail[]>> => {
      try {
        const res = await axios.get(urls.olympiadSchool.base(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
  },
  examination: {
    getDate: async ({
      params,
    }: Config): Promise<APIResponse<ImportantDates[]>> => {
      try {
        const res = await axios.get(urls.examination.getDate(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
  },
  schoolLead: {
    create: async ({ data }: Config): Promise<APIResponse<null>> => {
      try {
        const res = await axios.post(urls.interestedSchool.createLead(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
  },
  waitlist: {
    create: async ({
      data,
      ...options
    }: Config): Promise<APIResponse<RegisteredUser>> => {
      try {
        const res = await axios.post(urls.waitlist.join(), data, options)
        return formatSuccessResponse(res)
      } catch (e) {
        const errorResponse = formatErrorResponse(e)
        return errorResponse
      }
    },
  },
}
