import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { ValueOf } from "utils/helpers"

import { StripePaymentStatus } from "../applicationForm/med/types"

import {
  BillingAddressType,
  CartlessOrder,
  CreateBillingAddressResponse,
  OlympiadPrice,
  Order,
  PaymentMethod,
} from "./types"
import { urls } from "./urls"

export default {
  getOlympiadPrices: async ({
    data,
  }: Config): Promise<APIResponse<OlympiadPrice>> => {
    try {
      const res = await axios.post(urls.olympiadPrices(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createBillingAddress: async ({
    data,
  }: Config): Promise<APIResponse<CreateBillingAddressResponse>> => {
    try {
      const res = await axios.post(urls.billingAddress(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listBillingAddress: async (): Promise<APIResponse<BillingAddressType[]>> => {
    try {
      const res = await axios.get(urls.billingAddress())
      const formatted: APIResponse<BillingAddressType[]> =
        formatSuccessResponse(res)

      // We need to filter out the invalid data.
      formatted.data = formatted.data.filter(
        x => x.address != null && x.state != null
      )
      return formatted
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createCartlessOrder: async ({
    data,
  }: Config): Promise<APIResponse<CartlessOrder>> => {
    try {
      const res = await axios.post(urls.cartlessOrder(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getPaymentMethod: async ({
    urlParams: { planId },
    params,
  }: ConfigWithURLParams): Promise<APIResponse<PaymentMethod[]>> => {
    try {
      const res = await axios.post(
        urls.getPaymentMethod(planId),
        {},
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveOrder: async ({
    urlParams: { orderId },
  }: ConfigWithURLParams): Promise<APIResponse<Order>> => {
    try {
      const res = await axios.get(urls.retrieveOrder(orderId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getPaymentStatus: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<
    APIResponse<{ status: ValueOf<typeof StripePaymentStatus> }>
  > => {
    try {
      const res = await axios.get(urls.paymentStatus(urlParams.paymentPlanId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  cancelPayment: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(
        urls.cancel(urlParams.paymentPlanId),
        {},
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
