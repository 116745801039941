import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  City,
  Country,
  Currency,
  Curriculum,
  Language,
  QualificationField,
  QualificationLevel,
  State,
  Subject,
  TeachingLevel,
  TeachingRole,
} from "./types"
import { urls } from "./urls"

export default {
  listCurrencies: async ({
    params,
  }: Config): Promise<APIResponse<Currency[]>> => {
    try {
      const res = await axios.get(urls.currencies.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  subjects: {
    // This is bulk-create
    create: async ({ data }: Config): Promise<APIResponse<Subject[]>> => {
      try {
        const res = await axios.post(urls.subjects.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<Subject[]>> => {
      try {
        const res = await axios.get(urls.subjects.list(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listCountries: async ({
    params,
  }: Config): Promise<APIResponse<Country[]>> => {
    try {
      const res = await axios.get(urls.countries.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listStates: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"countryId">): Promise<APIResponse<State[]>> => {
    try {
      const res = await axios.get(urls.states.list(urlParams.countryId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listSubjects: async ({ params }: Config): Promise<APIResponse<Subject[]>> => {
    try {
      const res = await axios.get(urls.subjects.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listLanguages: async ({
    params,
  }: Config): Promise<APIResponse<Language[]>> => {
    try {
      const res = await axios.get(urls.languages.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  teachingLevel: {
    create: async ({ data }: Config): Promise<APIResponse<TeachingLevel[]>> => {
      try {
        const res = await axios.post(urls.teachingLevels.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<TeachingLevel[]>> => {
      try {
        const res = await axios.get(urls.teachingLevels.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  listCities: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"stateId">): Promise<APIResponse<City[]>> => {
    try {
      const res = await axios.get(urls.cities.list(urlParams.stateId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      const errorResponse = formatErrorResponse(e)
      return errorResponse
    }
  },

  curriculum: {
    create: async ({ data }: Config): Promise<APIResponse<Curriculum[]>> => {
      try {
        const res = await axios.post(urls.curricula.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<Curriculum[]>> => {
      try {
        const res = await axios.get(urls.curricula.list(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  teachingRole: {
    create: async ({ data }: Config): Promise<APIResponse<TeachingRole[]>> => {
      try {
        const res = await axios.post(urls.teachingRoles.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<TeachingRole[]>> => {
      try {
        const res = await axios.get(urls.teachingRoles.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listQualificationLevels: async (): Promise<
    APIResponse<QualificationLevel[]>
  > => {
    try {
      const res = await axios.get(urls.qualificationLevels.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  qualificationFields: {
    // This is bulk-create
    create: async ({
      data,
    }: Config): Promise<APIResponse<QualificationField[]>> => {
      try {
        const res = await axios.post(urls.qualificationFields.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    list: async ({
      params: { page = -1 },
    }: Config): Promise<APIResponse<QualificationField[]>> => {
      try {
        const res = await axios.get(urls.qualificationFields.list(), {
          params: { page },
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  detectCountry: async (): Promise<APIResponse<Country>> => {
    try {
      const res = await axios.get(urls.countries.current())
      return formatSuccessResponse(res)
    } catch (e) {
      const errorResponse = formatErrorResponse(e)
      return errorResponse
    }
  },
}
