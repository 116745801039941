import axios from "api/axios"
import {
  APIResponse,
  ConfigWithURLParams,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  Certificate,
  Masterclass,
  RegisteredUser,
  RegisterResponse,
} from "./types"
import { urls } from "./urls"

export default {
  get: async ({
    options,
    urlParams: { masterclassSlug },
  }: ConfigWithURLParams): Promise<APIResponse<Masterclass>> => {
    try {
      const res = await axios.get(urls.get(masterclassSlug), {
        // @ts-ignore Custom property
        preventRefreshRedirect: options?.preventRefreshRedirect,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  registration: {
    create: async ({
      data,
      urlParams: { masterclassSlug },
    }: ConfigWithURLParams): Promise<APIResponse<RegisterResponse>> => {
      try {
        const res = await axios.post(urls.register(masterclassSlug), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    get: async ({
      urlParams: { registrationId },
    }: ConfigWithURLParams): Promise<APIResponse<RegisteredUser>> => {
      try {
        const res = await axios.get(urls.verifyRegistration(registrationId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  list: async ({
    options,
    urlParams: params,
  }: ConfigWithURLParams): Promise<PaginatedAPIResponse<Masterclass[]>> => {
    try {
      const res = await axios.get(urls.list(), {
        params,
        // @ts-ignore Custom property
        preventRefreshRedirect: options?.preventRefreshRedirect,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  certificate: {
    getRegistrationId: async ({
      urlParams: { masterclassSlug },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        registrationId: string
      }>
    > => {
      try {
        const res = await axios.get(urls.getRegistrationId(masterclassSlug))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    get: async ({
      urlParams: { registrationId },
    }: ConfigWithURLParams): Promise<APIResponse<Certificate>> => {
      try {
        const res = await axios.get(urls.getCertificate(registrationId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    download: async ({
      urlParams: { certificateId },
      headers,
    }: ConfigWithURLParams): Promise<APIResponse<any>> => {
      try {
        const res = await axios.get(urls.downloadCertificate(certificateId), {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
