import { useEffect, useState } from "react"

import { BROWSER_STORAGE_KEYS } from "utils/constants"

const useReferrerTracking = () => {
  const [referrer, setReferrer] = useState<string | null>(null)

  useEffect(() => {
    const storedReferrer = sessionStorage.getItem(
      BROWSER_STORAGE_KEYS.referrerTracking
    )
    setReferrer(storedReferrer)

    if (storedReferrer === null) {
      const newReferrer = document.referrer
      sessionStorage.setItem(BROWSER_STORAGE_KEYS.referrerTracking, newReferrer)
      setReferrer(newReferrer)
    }
  }, [])

  return referrer
}

export default useReferrerTracking
