/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react"

import { theme } from "@suraasa/placebo-ui-legacy"
import { createPortal } from "react-dom"

import { ProviderContext, ToastContext } from "./ToastContext"
import { ToastItem } from "./ToastItem"
import { Toast, ToastKey, ToastOptions } from "./types"
import { InitializeToast } from "."

type Reducer = (state: State) => State

export const isDefined = (value: string | null | undefined | number): boolean =>
  !!value || value === 0

interface State {
  toasts: Toast[]
  queue: Toast[]
  contextValue: ProviderContext
}

type ToastMessage = Toast["message"]

type Props = {
  children?: React.ReactNode
  domRoot?: Element
}

class ToastProvider extends Component<Props, State> {
  maxToast = 4

  constructor(props: Props) {
    super(props)
    this.state = {
      toasts: [],
      queue: [],
      contextValue: {
        enqueueToast: this.enqueueToast.bind(this),
        closeToast: this.closeToast.bind(this),
      },
    }
  }

  /**
   * Adds a new toast to the queue to be presented.
   * Returns generated or user defined key referencing the new toast or null
   */
  enqueueToast = (
    message: ToastMessage,
    opts: ToastOptions = {}
  ): ToastKey | undefined => {
    if (!message) return

    const id = new Date().getTime() + Math.random()

    const toast: Toast = {
      key: id,
      message,
      open: true,
      ...opts,
    }

    this.setState(state =>
      this.handleDisplayToast({
        ...state,
        queue: [...state.queue, toast],
      })
    )

    return id
  }

  /**
   * Reducer: Display toast if there's space for it. Otherwise, immediately
   * begin dismissing the oldest message to start showing the new one.
   */
  handleDisplayToast: Reducer = state => {
    const { toasts } = state
    if (toasts.length >= this.maxToast) {
      return this.handleDismissOldest(state)
    }
    return this.processQueue(state)
  }

  /**
   * Reducer: Display items (notifications) in the queue if there's space for them.
   */
  // eslint-disable-next-line class-methods-use-this
  processQueue: Reducer = state => {
    const { queue, toasts } = state
    if (queue.length > 0) {
      return {
        ...state,
        toasts: [...toasts, queue[0]],
        queue: queue.slice(1, queue.length),
      }
    }
    return state
  }

  /**
   * Reducer: Hide oldest toast on the screen because there exists a new one which we have to display.
   * (ignoring the one with 'persist' flag. i.e. explicitly told by user not to get dismissed).
   *
   * Note 1: If there is already a message leaving the screen, no new messages are dismissed.
   * Note 2: If the oldest message has not yet entered the screen, only a request to close the
   *         toast is made. Once it entered the screen, it will be immediately dismissed.
   */
  // eslint-disable-next-line class-methods-use-this
  handleDismissOldest: Reducer = state => {
    let popped = false

    const toasts = state.toasts.map(item => {
      if (!popped) {
        popped = true

        return {
          ...item,
          open: false,
        }
      }

      return { ...item }
    })

    return { ...state, toasts }
  }

  /**
   * Hide a toast after its timeout.
   */
  handleCloseToast: any = (
    event: null,
    reason: string,
    key: ToastKey | undefined
  ) => {
    // should not use createChainedFunction for onClose.
    // because this.closeToast called this function
    // if (this.props.onClose) {
    //   this.props.onClose(event, reason, key)
    // }

    if (reason === "REASONS.CLICKAWAY") return
    const shouldCloseAll = key === undefined

    this.setState(({ toasts, queue }) => ({
      toasts: toasts.map(item => {
        if (!shouldCloseAll && item.key !== key) {
          return { ...item }
        }

        return item
        // return item.entered
        //   ? { ...item, open: false }
        //   : { ...item, requestClose: true }
      }),
      queue: queue.filter(item => item.key !== key), // eslint-disable-line react/no-unused-state
    }))
  }

  /**
   * Close toast with the given key
   */
  closeToast: ProviderContext["closeToast"] = key => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.handleCloseToast(null, "REASONS.INSTRUCTED", key)
  }

  /**
   * When we set open attribute of a toast to false (i.e. after we hide a toast),
   * it leaves the screen and immediately after leaving animation is done, this method
   * gets called. We remove the hidden toast from state and then display notifications
   * waiting in the queue (if any). If after this process the queue is not empty, the
   * oldest message is dismissed.
   */
  handleExitedToast = (key: Toast["key"]) => {
    if (!isDefined(key)) {
      throw new Error("handleExitedToast Cannot be called with undefined key")
    }

    this.setState(state => {
      const newState = this.processQueue({
        ...state,
        toasts: state.toasts.filter(item => item.key !== key),
      })

      return newState
    })
  }

  render() {
    const { contextValue } = this.state
    const { domRoot, children } = this.props

    const toasts = (
      <div
        style={{
          position: "fixed",
          top: 20,
          right:
            typeof window !== "undefined" && window.innerWidth < 600
              ? "50%"
              : 20,
          transform:
            typeof window !== "undefined" && window.innerWidth < 600
              ? "translate(50%, 0)"
              : "",
          zIndex: theme.zIndex.toast,
        }}
      >
        {this.state.toasts.map(x => (
          <ToastItem
            item={x}
            key={x.key}
            onExit={({ key }) => this.handleExitedToast(key)}
          />
        ))}
      </div>
    )

    return (
      <ToastContext.Provider value={contextValue}>
        <InitializeToast />

        {children}
        {/* @ts-expect-error safe to do */}
        {domRoot ? createPortal(toasts, domRoot) : toasts}
      </ToastContext.Provider>
    )
  }
}

export default ToastProvider
