/* eslint-disable @typescript-eslint/no-throw-literal */
import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { CartlessOrder } from "./types"
import { urls } from "./urls"

export default {
  createOrder: async ({
    data,
  }: Config): Promise<APIResponse<CartlessOrder>> => {
    try {
      const res = await axios.post(urls.orders.create(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
