import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getPolluxURL = getServiceURL(ServicePrefix.pollux)

export const urls = validateUrls({
  retrieveOrder: orderId => getPolluxURL(`/v1/orders/${orderId}/`),
  olympiadPrices: () => "/olympiad/prices/",
  cartlessOrder: () => getPolluxURL("/v1/cartless-orders/"),

  getPaymentMethod: paymentPlanId =>
    getPolluxURL(`/v1/payment-plans/${paymentPlanId}/methods/`),

  billingAddress: () => "/catalogue/billing-address/",
  paymentStatus: paymentPlanId =>
    getPolluxURL(`/v1/payment-plans/${paymentPlanId}/status/`),
  cancel: paymentPlanId =>
    getPolluxURL(`/v1/payment-plans/${paymentPlanId}/cancel/`),
})
