import { validateUrls } from "api/types"

export const urls = validateUrls({
  orders: {
    base: () => "/pollux/v1/orders",
    detail: id => `/pollux/v1/orders/${id}/`,
    invoice: transactionId => `/libra/v1/invoices/${transactionId}/`,
    create: () => "/pollux/v1/cartless-orders",
  },
})
