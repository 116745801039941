/* eslint-disable import/no-cycle */
import camelCase from "lodash/camelCase"

import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import { BROWSER_STORAGE_KEYS, Platforms, SSO_URL } from "utils/constants"

import { LocalStorage, SessionStorage } from "./storageFactory"

// check session and local storage support
export function isStorageSupported() {
  try {
    localStorage.setItem("__TEST_LOCAL_STORAGE", "true")
    localStorage.removeItem("__TEST_LOCAL_STORAGE")
    sessionStorage.setItem("__TEST_SESSION_STORAGE", "true")
    sessionStorage.removeItem("__TEST_SESSION_STORAGE")
  } catch (e) {
    // alerting the user to prevent further errors or App issues
    alert(
      "Your browser has cookies disabled. Make sure that your cookies are enabled. Press OK to reload"
    )
    // reload browser to prevent further error popups and check if user enabled storage
    window.location.reload()
    return false
  }
  // returns true if storages are supported
  return true
}

export function getAuthInfo(keyName: string = BROWSER_STORAGE_KEYS.auth) {
  if (typeof window === "undefined") return null

  const authInfo = isStorageSupported()
    ? localStorage.getItem(keyName) || sessionStorage.getItem(keyName)
    : null

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  return authInfo
}
export type PlatformsWhichCanViewLearnerProfile =
  | Platforms.student
  | Platforms.applicationFormAdmin

export function getOtherPlatformAuthInfo(
  platform: PlatformsWhichCanViewLearnerProfile
): AuthData | null {
  switch (platform) {
    case Platforms.student: {
      return getAuthInfo(BROWSER_STORAGE_KEYS.auth)
    }
    case Platforms.applicationFormAdmin: {
      return getAuthInfo(BROWSER_STORAGE_KEYS.applicationFormAdminAuth)
    }
    default:
      return null
  }
}

export function clearOtherPlatformAuthInfo(
  platform: PlatformsWhichCanViewLearnerProfile
): number {
  switch (platform) {
    case Platforms.student: {
      localStorage.removeItem(BROWSER_STORAGE_KEYS.auth)
      return 0
    }
    case Platforms.applicationFormAdmin: {
      localStorage.removeItem(BROWSER_STORAGE_KEYS.applicationFormAdminAuth)
      return 0
    }
    default:
      return 0
  }
}

export function setOtherPlatformAuthInfo(
  authInfo: AuthData,
  platform: PlatformsWhichCanViewLearnerProfile
): number {
  switch (platform) {
    case Platforms.student: {
      localStorage.setItem(BROWSER_STORAGE_KEYS.auth, JSON.stringify(authInfo))
      return 0
    }
    case Platforms.applicationFormAdmin: {
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.applicationFormAdminAuth,
        JSON.stringify(authInfo)
      )
      return 0
    }
    default:
      return 0
  }
}

const addSecondsToDate = (date: Date, seconds: number) =>
  new Date(date.setSeconds(date.getSeconds() + seconds))

export function saveAuthInfo(authInfo: AuthData) {
  const { auth } = BROWSER_STORAGE_KEYS

  const oldAuthToken = getAuthInfo()?.accessToken
  const newAuthToken = authInfo.accessToken

  LocalStorage.setItem(
    auth,
    JSON.stringify({
      ...authInfo,
      expiresAt:
        oldAuthToken !== newAuthToken
          ? // Reduce expiry time by 10 minutes so we can refresh prematurely
            addSecondsToDate(
              new Date(),
              authInfo.expiresIn - 10 * 60
            ).toISOString()
          : authInfo.expiresAt,
    })
  )
}

export function clearAuthInfo() {
  const { auth, applicationFormAdminAuth } = BROWSER_STORAGE_KEYS
  LocalStorage.removeItem(auth)
  LocalStorage.removeItem(applicationFormAdminAuth)
  SessionStorage.clear()
}

export const generateLoginURL = ({
  redirectUrl,
  next,
  ...rest
}: { redirectUrl: string; next: string } & { [key: string]: string }) => {
  let redirectTo = encodeURIComponent(redirectUrl)
  if (next) redirectTo = `${redirectTo}?next=${encodeURIComponent(next)}`

  const restParams = Object.keys(rest)
    .map(key => `${key}=${rest[key]}`)
    .join("&")
  const url = `${SSO_URL}/?redirect-url=${redirectTo}&${restParams}`

  return url
}
