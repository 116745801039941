/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-self-import
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
// eslint-disable-next-line import/no-self-import
import Axios from "axios"

import { getAuthInfo } from "utils/auth"
import { AUTHORIZATION_HEADER_PREFIX, Platforms } from "utils/constants"

import { urls } from "./resources/users/urls"
import { getCurrentAuth, getCurrentPlatform } from "./platform"
import { refreshToken } from "./refreshToken"
import { paramsSerializer, transformRequest, transformResponse } from "./utils"

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
  withCredentials: true,
  paramsSerializer,
  transformRequest: [
    transformRequest,
    ...(Array.isArray(Axios.defaults.transformRequest)
      ? Axios.defaults.transformRequest
      : []),
  ],
  transformResponse: [
    ...(Array.isArray(Axios.defaults.transformResponse)
      ? Axios.defaults.transformResponse
      : []),
    transformResponse,
  ],
  headers: {
    "Content-Type": "application/json",
    Language: "en",
  },
})

const authorizationIsEmpty = (request: AxiosRequestConfig) =>
  /**
   * If we send null in Authorization header from where the API is being called, axios transforms it into an empty object `{}`
   * That is why we are checking for object length and not null
   */
  request.headers?.Authorization != null &&
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(request.headers.Authorization).length === 0

axios.interceptors.request.use(request => {
  /**
   * Empty "Authorization" header means that we don't want to send this Authorization in the request even if accessToken is present in the localStorage
   * Example: in refresh token API call, we explicitly send Authorization: null from the API call.
   */
  if (authorizationIsEmpty(request)) {
    delete request.headers?.Authorization
  } else {
    const auth = getCurrentAuth()
    // console.log(`Hitting API as: ${auth?.user.email}`)

    const token = auth?.accessToken
    if (token) {
      // Refreshing Access token in-case of expiry
      const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${token}`
      request.headers.Authorization = tokenString
    }
  }

  if (request.headers) request.headers.Language = "en"

  return request
})

const interceptor = async (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    const refreshed = await refreshToken()
    if (refreshed) {
      // Retrying the failed api call with new access token
      if (error.response.config.headers) {
        error.response.config.headers.Authorization = `${AUTHORIZATION_HEADER_PREFIX} ${
          getAuthInfo()?.accessToken
        }`
      }
      return axios(error.response.config)
    }
  }

  return Promise.reject(error)
}
const successInterceptor = async (response: AxiosResponse<any>) => {
  // Verify access token API
  if (response.config.url === urls.auth.verifyAccessToken()) {
    const platform = getCurrentPlatform()
    /**
     * We don't want token verification feature for other platforms
     */
    if (
      response.data.data.verified === false &&
      platform === Platforms.student
    ) {
      const refreshed = await refreshToken()
      if (refreshed) {
        const newToken = getAuthInfo()?.accessToken
        if (newToken) {
          // Retry the request with new token
          response.config.data = JSON.stringify({ token: newToken })
          return axios(response.config)
        }
      }
    }
  }

  return Promise.resolve(response)
}
axios.interceptors.response.use(successInterceptor, interceptor)

export default axios
