/* eslint-disable import/no-cycle */
import { getAuthInfo } from "./auth"

const MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID
const DEBUG = process.env.NODE_ENV === "development"
export interface CustomWindow extends Window {
  gtag?: (...args: any[]) => void
}
declare let window: CustomWindow

export const GA_EVENTS = {
  ito_registration_started: "ito_registration_started",
  ito_payment_started: "ito_payment_started",
  ito_payment_success: "ito_payment_success",
  // ito_payment_failed: "ito_payment_failed",
  ito_registration_completed: "ito_registration_completed",
  ito_resend_otp: "ito_resend_otp",
  ito_verification_completed: "ito_verification_completed",
  ito_password_created: "ito_password_created",
  ito_currently_teaching: "ito_currently_teaching",
  ito_school_filled: "ito_school_filled",
  ito_teaching_level_filled: "ito_teaching_level_filled",
  ito_personal_details_filled: "ito_personal_details_filled",
  ito_education_level_filled: "ito_education_level_filled",
  ito_selected_billing_address: "ito_selected_billing_address",
  ito_created_billing_address: "ito_created_billing_address",
  ito_created_new_school: "ito_created_new_school",
  ito_waitlist_2024: "ito_waitlist_2024",
  // ito_signup: "ito_signup",
  purchase: "purchase",
  click: "click",

  // Talk to a mentor
  ttmBasicDetailsFilled: "ttm_basic_details_filled",
  ttmLocationFilled: "ttm_location_filled",
  ttmExperienceInTeachingFilled: "ttm_experience_in_teaching_filled",
  ttmYearsOfExperienceFilled: "ttm_years_of_experience_filled",
  ttmEducationalInstitutesFilled: "ttm_educational_institutes_filled",
  ttmTeachingSubjectsFilled: "ttm_teaching_subjects_filled",
  ttmSubjectQualificationFilled: "ttm_subject_qualification_filled",
  ttmTeachingQualificationFilled: "ttm_teaching_qualification_filled",
  ttmCareerObjectiveFilled: "ttm_career_objective_filled",
  ttmTeachingLocationFilled: "ttm_teaching_location_filled",
  ttmPreferredLanguageFilled: "ttm_preferred_language_filled",
  ttmIntentOfSessionFilled: "ttm_intent_of_session_filled",
  ttmWhatSuraasaDoesFilled: "ttm_what_suraasa_does_filled",
  ttmIsInterestedFilled: "ttm_is_interested_filled",
  ttmAgeFilled: "ttm_age_filled",
  ttmGenderFilled: "ttm_gender_filled",
  ttmSessionBooked: "ttm_session_booked",

  // Application Form
  med_application_fee_payment_success: "med_application_fee_payment_success",
  pgctl_application_fee_payment_success:
    "pgctl_application_fee_payment_success",
} as const

// export const GA_INTENT = {}

const gtag = (...params: any) => {
  if (DEBUG) {
    // console.info("> Logging event to GA", params)
    return
  }

  if (typeof window !== "undefined" && window.gtag) {
    if (MEASUREMENT_ID) {
      // console.info("> Logging event to GA", params)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return window.gtag(...params)
    }
  }
}

export const GA = {
  trackEvent(
    name: ValueOf<typeof GA_EVENTS>,
    payload?: Record<string, unknown>
  ) {
    const userIdOverride =
      payload && "user_id" in payload ? payload.user_id : null

    gtag("event", name, {
      ...payload,
      user_id: userIdOverride ?? getAuthInfo()?.user.uuid,
    })
  },
  setUserId(userId: string) {
    gtag("config", MEASUREMENT_ID, { user_id: userId })
  },
  init() {
    if (!MEASUREMENT_ID) return

    const gtagScript = document.createElement("script")
    gtagScript.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`
    )
    gtagScript.setAttribute("async", "true")
    document.head.appendChild(gtagScript)

    const script2 = document.createElement("script")
    script2.innerHTML = `
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "${MEASUREMENT_ID}")
    `
    document.head.appendChild(script2)
  },
  trackElement(trackingId: string, meta: TrackingMeta) {
    return {
      "data-trackingid": trackingId,
      ...(meta?.source && { "data-source": meta.source }),
      ...(meta?.purpose && { "data-purpose": meta.purpose }),
      ...(meta?.feature && { "data-feature": meta.feature }),
    }
  },
}

export const TRACKING_DATA = {
  source: {
    itoLandingPage: "ito-landing-page",
    teacherReportLandingPage: "teacher-report-landing-page",
    itoLandingPageHero: "ito-landing-page-hero",
    itoLandingPageNavbar: "ito-landing-page-navbar",
    suraasaLandingPageHero: "suraasa-landing-page-hero",
    itoPersistentBanner: "ito-persistent-banner",
    itoPopup: "ito-popup",
    itoSchoolLandingPage: "ito-school-landing-page",
    itoRegistrationPage: "ito-register-page",
    website: "website",
  },
  purpose: {
    register: "register",
    login: "login",
    learnMore: "learn-more",
    viewSampleReport: "view-sample-report",
    contactUs: "contact-us",
  },
  feature: {
    masterclass: "masterclass",
    ito: "ito",
  },
} as const

type ValueOf<T> = T[keyof T]

export type TrackingMeta = {
  source?: ValueOf<typeof TRACKING_DATA["source"]>
  purpose: ValueOf<typeof TRACKING_DATA["purpose"]>
  feature: ValueOf<typeof TRACKING_DATA["feature"]>
}
