import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getGravityURL = getServiceURL(ServicePrefix.gravity)
export const urls = validateUrls({
  languages: {
    list: () => getGravityURL("/languages/"),
  },

  teachingLevels: {
    list: () => getGravityURL("/teaching-levels/"),
    create: () => getGravityURL("/teaching-levels/create/"),
  },
  teachingRoles: {
    list: () => getGravityURL("/teaching-roles/"),
    create: () => getGravityURL("/teaching-roles/create/"),
  },
  currencies: {
    list: () => getGravityURL("/currencies/"),
  },
  curricula: {
    list: () => getGravityURL("/curricula/"),
    create: () => getGravityURL("/curricula/create/"),
  },
  subjects: {
    list: () => getGravityURL("/subjects/"),
    create: () => getGravityURL("/subjects/create/"),
  },
  countries: {
    list: () => getGravityURL("/countries/"),
    current: () => "/global/countries/current",
  },
  qualificationLevels: {
    list: () => getGravityURL("/qualification-levels/"),
  },
  qualificationFields: {
    list: () => getGravityURL("/qualification-fields/"),
    create: () => getGravityURL("/qualification-fields/create/"),
  },
  states: {
    list: countryId => getGravityURL(`/countries/${countryId}/states/`),
  },
  cities: {
    list: stateId => getGravityURL(`/states/${stateId}/cities/`),
  },
})
