export default [
  {
    id: "google",
    source: "Google",
    medium: "Organic Search",
  },
  {
    id: "bing",
    source: "Bing",
    medium: "Organic Search",
  },
  {
    id: "baidu",
    source: "Baidu",
    medium: "Organic Search",
  },
  {
    id: "yahoo",
    source: "Yahoo",
    medium: "Organic Search",
  },
  {
    id: "yandex",
    source: "Yandex",
    medium: "Organic Search",
  },
  {
    id: "duckduckgo",
    source: "DuckDuckGo",
    medium: "Organic Search",
  },
  {
    id: "ask",
    source: "Ask",
    medium: "Organic Search",
  },
  {
    id: "naver",
    source: "Naver",
    medium: "Organic Search",
  },
  {
    id: "aol",
    source: "AOL",
    medium: "Organic Search",
  },
  {
    id: "wolframalpha",
    source: "WolframAlpha",
    medium: "Organic Search",
  },
  {
    id: "qwant",
    source: "Qwant",
    medium: "Organic Search",
  },
  {
    id: "startpage",
    source: "StartPage",
    medium: "Organic Search",
  },
  {
    id: "dogpile",
    source: "Dogpile",
    medium: "Organic Search",
  },
  {
    id: "excite",
    source: "Excite",
    medium: "Organic Search",
  },
  {
    id: "lycos",
    source: "Lycos",
    medium: "Organic Search",
  },
  {
    id: "webcrawler",
    source: "WebCrawler",
    medium: "Organic Search",
  },
  {
    id: "infospace",
    source: "InfoSpace",
    medium: "Organic Search",
  },
  {
    id: "swisscows",
    source: "Swisscows",
    medium: "Organic Search",
  },
  {
    id: "gigablast",
    source: "Gigablast",
    medium: "Organic Search",
  },
  {
    id: "searchencrypt",
    source: "Search Encrypt",
    medium: "Organic Search",
  },
  {
    id: "boardreader",
    source: "Boardreader",
    medium: "Organic Search",
  },
  {
    id: "goodsearch",
    source: "Goodsearch",
    medium: "Organic Search",
  },
  {
    id: "gibiru",
    source: "Gibiru",
    medium: "Organic Search",
  },
  {
    id: "yippy",
    source: "Yippy",
    medium: "Organic Search",
  },
  {
    id: "searx",
    source: "Searx",
    medium: "Organic Search",
  },
  {
    id: "facebook",
    source: "Facebook",
    medium: "Social",
  },
  {
    id: "youtube",
    source: "YouTube",
    medium: "Social",
  },
  {
    id: "linkedin",
    source: "LinkedIn",
    medium: "Social",
  },
  {
    id: "instagram",
    source: "Instagram",
    medium: "Social",
  },
  {
    id: "twitter",
    source: "Twitter",
    medium: "Social",
  },
  {
    id: "quora",
    source: "Quora",
    medium: "Social",
  },
  {
    id: "telegram",
    source: "Telegram",
    medium: "Social",
  },
  {
    id: "reddit",
    source: "Reddit",
    medium: "Social",
  },
]
