import FingerprintJS from "@fingerprintjs/fingerprintjs"

import { BROWSER_STORAGE_KEYS } from "./constants"
import { LocalStorage } from "./storageFactory"

export const getFingerprint = async () => {
  if (typeof window === "undefined") {
    console.error("Window is not defined")
    let tempDeviceId = LocalStorage.getItem(BROWSER_STORAGE_KEYS.tempDeviceId)
    if (tempDeviceId !== null) {
      return tempDeviceId
    }
    tempDeviceId = `fail-${Date.now()}`
    LocalStorage.setItem(BROWSER_STORAGE_KEYS.tempDeviceId, tempDeviceId)
    return tempDeviceId
  }
  // Initialize an agent at application startup.
  const fpPromise = FingerprintJS.load()
  try {
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    const { visitorId } = result
    return visitorId
  } catch (error) {
    console.error(error)
    let tempDeviceId = LocalStorage.getItem(BROWSER_STORAGE_KEYS.tempDeviceId)
    if (tempDeviceId !== null) {
      return tempDeviceId
    }
    tempDeviceId = `fail-${Date.now()}`
    LocalStorage.setItem(BROWSER_STORAGE_KEYS.tempDeviceId, tempDeviceId)
    return tempDeviceId
  }
}
