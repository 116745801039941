import { useEffect, useState } from "react"

import api from "api"
import { Country } from "api/resources/global/types"

type Props = {
  cache?: boolean
}

const STORAGE_KEY_NAME = "currentCountry"

// const getInitialValue = () => {
//   if (typeof window === "undefined") return null
//   const cachedValue = sessionStorage.getItem(STORAGE_KEY_NAME)
//   if (cachedValue) {
//     return JSON.parse(cachedValue) as Country
//   }
//   return null
// }
/**
 * Doesn't return country uuid for some reason
 */
const useDetectCountry = ({ cache }: Props = { cache: true }) => {
  const [country, setCountry] = useState<Country | null | undefined>(undefined)

  useEffect(() => {
    const cachedValue = sessionStorage.getItem(STORAGE_KEY_NAME)
    if (cache && cachedValue) {
      setCountry(JSON.parse(cachedValue) as Country)
      return
    }

    const detectCountry = async () => {
      const res = await api.global.detectCountry()
      if (res.isSuccessful) {
        setCountry(res.data)
        sessionStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(res.data))
      } else {
        setCountry(null)
      }
    }
    detectCountry()
  }, [cache])

  return country
}

export default useDetectCountry
