// eslint-disable-next-line import/no-cycle
import { ValueOf } from "utils/helpers"

import { Country, State } from "../global/types"

export type SkillEvidenceCount = {
  evidences: number
  skill: string
}

export enum ItemType {
  COURSE = "course",
  QUALIFICATION = "qualification",
  CERTIFICATION = "certification",
}

export type ItemCertificate = {
  serialNumber: string
}

export type UserCertificate = {
  credentials: Array<{
    additionalDetails?: {
      registeredUser?: {
        country?: Pick<Country, "name" | "uuid">
        state?: Pick<State, "name" | "uuid">
      }
    }
    id: number
    serialNumber: string
    organization: {
      abbreviation: string
      id: number
      imageUrl: null | string
      name: string
      slug: string
    }
    issuedAt: string
    lowResImage: string | null
    highResImage?: string | null // This field will be in response only if the isOwner is true
    pdfFile?: string | null // This field will be in response only if the isOwner is true
    text: string | null
  }>
  item: {
    uuid: string
    name: string
    image?: string
    slug: string
    itemType: ItemType
    itemLevel: null | number
    duration: number
    olympiad?: {
      id: number
      name: string
      slug:
        | "international-teacher-olympiad-2022"
        | "international-teacher-olympiad-2023"
      year: 2022 | 2023
    }
  }
  user: {
    fullName: string
    username: string
  }
  isOwner: boolean
}

export const ITOCertificateType = {
  PARTICIPATION: "olympiad-participation",
  EXCELLENCE: "olympiad-excellence",
  MERIT_1_PERCENTILE: "olympiad-merit-1-percentile",
  MERIT_10_PERCENTILE: "olympiad-merit-10-percentile",
  MERIT_30_PERCENTILE: "olympiad-merit-30-percentile",
} as const

export const getITOCertificateType = (
  slug: string
): ValueOf<typeof ITOCertificateType> => {
  switch (slug) {
    case "international-teacher-olympiad-2022-participation":
    case "international-teacher-olympiad-2023-participation":
      return ITOCertificateType.PARTICIPATION
    case "international-teacher-olympiad-2022-excellence":
    case "international-teacher-olympiad-2023-excellence":
      return ITOCertificateType.EXCELLENCE
    case "international-teacher-olympiad-2022-merit-1-percentile":
    case "international-teacher-olympiad-2023-merit-1-percentile":
      return ITOCertificateType.MERIT_1_PERCENTILE
    case "international-teacher-olympiad-2022-merit-10-percentile":
    case "international-teacher-olympiad-2023-merit-10-percentile":
      return ITOCertificateType.MERIT_10_PERCENTILE
    case "international-teacher-olympiad-2022-merit-30-percentile":
    case "international-teacher-olympiad-2023-merit-30-percentile":
      return ITOCertificateType.MERIT_30_PERCENTILE
    default:
      throw new Error(`Invalid slug ${slug}`)
  }
}

export const itoDate = {
  2022: "15th October 2022",
  2023: "7th October 2023",
}
