import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getAnalyticsURL = getServiceURL(ServicePrefix.analytics)
const getPhoenixURL = getServiceURL(ServicePrefix.phoenix)
export const urls = validateUrls({
  skillEvidence: {
    count: username =>
      getAnalyticsURL(`/public/${username}/skill-evidence-count/`),
  },
  userCertificates: {
    base: serialNumber => getPhoenixURL(`/v1/credentials/${serialNumber}/`),
    retrieveFromProfile: (username, itemSlug, itemType) =>
      getPhoenixURL(
        `/v1/users/${username}/${itemType}/${itemSlug}/credentials/`
      ),
    download: serialNumber =>
      `/enrollment/certificate/download/${serialNumber}/`,
  },
})
