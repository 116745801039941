/* eslint-disable import/no-cycle */
// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
import axios from "axios"

import { clearAuthInfo, getAuthInfo, saveAuthInfo } from "utils/auth"

import { urls } from "./resources/users/urls"

let isTokenRefreshing = false
const promises: (() => void)[] = []

const forceLogout = () => {
  if (typeof window !== "undefined") {
    clearAuthInfo()
    window.location.reload()
  }
}

// Not using api/axios here because of cyclical imports
export async function refreshToken(): Promise<boolean> {
  const authInfo = getAuthInfo()
  if (!authInfo) {
    forceLogout()
    return false
  }

  if (isTokenRefreshing) {
    // getting reference to promise's resolve method so we can call it from outside
    let resolvePromise: any
    const currentRequestPromise = new Promise<boolean>(resolve => {
      resolvePromise = resolve
    })
    // pushing all the promises in the array so they can be resolved later
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    promises.push(() => resolvePromise())

    // waiting for the new refresh token from first api call
    return currentRequestPromise
  }

  isTokenRefreshing = true

  let refreshed = false
  try {
    const { refreshToken: token } = authInfo

    if (!token) throw new Error("User is already logged out")

    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_ENDPOINT}/${urls.auth.refreshToken()}`,
      {
        refreshToken: token,
      },
      {
        headers: {
          // @ts-ignore We need to send null
          Authorization: null,
        },
      }
    )
    const newAuthInfo = {
      ...authInfo,
      expiresIn: res.data.data.expires_in,
      accessToken: res.data.data.access_token,
      refreshToken: res.data.data.refresh_token,
    }
    saveAuthInfo(newAuthInfo)
    refreshed = true
  } catch (e: any) {
    console.info("> Refresh token expired. Logging out.")
  }

  // resolving all the pending promises so awaited code can resume execution
  isTokenRefreshing = false
  promises.splice(0).forEach(callBack => {
    callBack()
  })

  if (!refreshed) {
    forceLogout()
  }

  return refreshed
}
