import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  AuthData,
  ClosedBetaOverview,
  ClosedBetaSignup,
  ResendVerificationEmail,
  StepsOverview,
} from "./types"
import { urls } from "./urls"

export default {
  closedBeta: {
    overview: async (): Promise<
      APIResponse<Omit<ClosedBetaOverview, "loading">>
    > => {
      try {
        const res = await axios.get(urls.closedBeta.overview())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    signup: async ({
      data,
    }: {
      data: ClosedBetaSignup
    }): Promise<
      APIResponse<
        | { registrationCompleted: false; resendAt: string }
        | { registrationCompleted: true }
      >
    > => {
      try {
        const res = await axios.post(urls.closedBeta.signup(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    resendVerificationEmail: async ({
      data,
    }: {
      data: ResendVerificationEmail
    }): Promise<
      APIResponse<
        | { registrationCompleted: false; resendAt: string }
        | { registrationCompleted: true }
      >
    > => {
      try {
        const res = await axios.post(
          urls.closedBeta.resendVerificationEmail(),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  generatePlatformToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(
        urls.auth.generatePlatformToken(),
        data,
        options
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  verifyAccessToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<{ verified: boolean }>> => {
    try {
      const res = await axios.post(urls.auth.verifyAccessToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  markAsComplete: async (): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.profile.markAsComplete())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  stepsOverview: async (): Promise<APIResponse<StepsOverview>> => {
    try {
      const res = await axios.get(urls.stepsOverview.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
