import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNormaURL = getServiceURL(ServicePrefix.norma)
const getSolisURL = getServiceURL(ServicePrefix.solis)
const getMercuryURL = getServiceURL(ServicePrefix.mercury)

export const urls = validateUrls({
  registeredUser: {
    verify: () => getSolisURL(`/v1/users/otp/verify/`),
    login: uuid => getSolisURL(`/v1/users/${uuid}/olympiad/login/`),
    base: () => getNormaURL("/v1/registrations/"),
    count: () => getNormaURL("/v1/registrations/count/"),
  },
  registration: {
    details: () => getNormaURL("/v1/registrations/details/"),
    getById: id => getNormaURL(`/v1/registrations/${id}/`),
  },
  olympiadSchool: {
    base: () => getNormaURL("/v1/olympiad-schools/"),
  },
  examination: {
    getDate: () => getNormaURL("/v1/extras/important-dates/"),
  },
  interestedSchool: {
    createLead: () => getMercuryURL("/leads/school-leads/"),
  },
  waitlist: {
    join: () => getMercuryURL("/leads/ito-waitlist/"),
  },
})
