import { createContext } from "react"

import { Toast, ToastKey, ToastOptions } from "./types"

export interface ProviderContext {
  enqueueToast: (
    message: Toast["message"],
    options?: ToastOptions
  ) => ToastKey | undefined
  closeToast: (key?: ToastKey) => void
}

export const ToastContext = createContext<ProviderContext>({
  enqueueToast: () => "",
  closeToast: () => {},
})
