import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  login: () => getSolisURL("/v1/auth/login/"),
  platformToken: () => getSolisURL(`/v1/auth/user-tokens/`),
  sendOtp: () => getSolisURL(`/v1/users/otp/`),
  userPassword: () => getSolisURL(`/v1/auth/set-password/`),
  verifyUserOtp: () => getSolisURL(`/v1/users/otp/verify`),
  signupSchoolRepresentative: () => "/v1/auth/school-representatives/signup/",
})
