/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable no-extend-native */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

;(function () {
  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (searchValue, replaceValue) {
      function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
      }

      return this.replace(
        new RegExp(escapeRegExp(searchValue), "g"),
        replaceValue
      )
    }
  }
})()
