import { useRouter } from "next/router"

type Props = {
  children?: React.ReactNode
}

const GlobalComponentWrapper = ({ children }: Props) => {
  const router = useRouter()

  // Prevent global banners/components to be rendered by iframes
  if (router.pathname.includes("iframes")) return null
  if (router.pathname.includes("/ito")) return null
  if (router.pathname.includes("/international-teachers-olympiad")) return null
  if (router.pathname.includes("application-form")) return null
  if (router.pathname === "/sso") return null

  // lp=1 means that the page is used in advertisements. We don't need distractions there
  if (router.query.lp === "1") return null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default GlobalComponentWrapper
