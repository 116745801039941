/* eslint-disable prefer-destructuring */
import { BROWSER_STORAGE_KEYS } from "./constants"
import websites from "./websites"

const getReferrer = () =>
  sessionStorage.getItem(BROWSER_STORAGE_KEYS.referrerTracking) ||
  document.referrer ||
  null

export const getInferredParams = (): { medium: string; source: string } => {
  const referrer = getReferrer()

  const mapped = {
    medium: "Organic",
    source: "Direct",
  }

  if (referrer) {
    const referrerURL = new URL(referrer)
    const chunks = referrerURL.hostname.split(".")
    let domain = ""
    if (chunks.length === 2) domain = chunks[0]

    if (chunks.length === 3) {
      domain = chunks[1]
      if (chunks[1] === "co") domain = chunks[0]
    }

    const obj = websites.find(({ id }) => id === domain)
    mapped.medium = obj?.medium || "Referral"
    mapped.source = obj?.source || "Referral"
  }

  return mapped
}
const getMappedParams = (params: Record<string, string | null>) => {
  const mappedParams = getInferredParams()

  if (params.source) {
    // @ts-expect-error can't make these properties optional
    delete mappedParams.source
  }

  if (params.medium) {
    // @ts-expect-error can't make these properties optional
    delete mappedParams.medium
  }

  return { ...params, ...mappedParams }
}

export const parseParam = (param: string | string[] | undefined | null) =>
  Array.isArray(param) ? param[0] : param ?? null

type Options = {
  includeReferrer?: boolean
}

export const parseUTMParams = <
  T extends Record<string, string | string[] | undefined | null>
>(
  params: T,
  options?: Options
): Record<string, string | null> => {
  // If all values are null then return null
  // if (Object.values(params).every(e => e === null)) return null

  const cleaned: Record<string, string | null> = {}
  for (const [k, v] of Object.entries(params)) {
    cleaned[k] = parseParam(v)
  }

  if (options?.includeReferrer) {
    return { ...getMappedParams(cleaned), referrer: getReferrer() }
  }

  return getMappedParams(cleaned)
}
