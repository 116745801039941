// Types
import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getMercuryURL = getServiceURL(ServicePrefix.mercury)

export const urls = validateUrls({
  form: () => getMercuryURL("/v1/counselling-forms/"),
  scheduledSessions: () => getMercuryURL("/leads/scheduled-sessions/"),
  userLead: () => getMercuryURL("/leads/user-lead/"),
})
