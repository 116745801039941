import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { ItemCertificate, SkillEvidenceCount, UserCertificate } from "./types"
import { urls } from "./urls"

export default {
  skillEvidenceCount: async ({
    urlParams: { username },
    params,
  }: ConfigWithURLParams): Promise<APIResponse<SkillEvidenceCount[]>> => {
    try {
      const res = await axios.get(urls.skillEvidence.count(username), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (error) {
      return formatErrorResponse(error)
    }
  },
  userCertificate: {
    retrieve: async ({
      urlParams: { serialNumber },
    }: ConfigWithURLParams): Promise<APIResponse<UserCertificate>> => {
      try {
        const res = await axios.get(urls.userCertificates.base(serialNumber))
        return formatSuccessResponse(res)
      } catch (error) {
        return formatErrorResponse(error)
      }
    },
    retrieveFromProfile: async ({
      urlParams: { username, itemType, itemSlug },
    }: ConfigWithURLParams): Promise<APIResponse<ItemCertificate>> => {
      try {
        const res = await axios.get(
          urls.userCertificates.retrieveFromProfile(
            username,
            itemSlug,
            itemType
          )
        )
        return formatSuccessResponse(res)
      } catch (error) {
        return formatErrorResponse(error)
      }
    },
  },
}
