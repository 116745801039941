import { useEffect } from "react"

import { useNetworkState } from "@uidotdev/usehooks"

const NetworkStatusLogging = () => {
  const network = useNetworkState()

  useEffect(() => {
    // @ts-expect-error defining a custom key
    window.networkStatus = network
    console.log(JSON.stringify(network))
  }, [network])

  return null
}

export default NetworkStatusLogging
