/* eslint-disable @typescript-eslint/no-throw-literal */
import axios from "api/axios"
import { Config, PaginatedAPIResponse } from "api/types"
import { formatErrorResponse, formatPaginatedResponse } from "api/utils"

import { Subscription } from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Subscription[]>> => {
    try {
      const res = await axios.get(urls.list(), { params })
      return formatPaginatedResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
