import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  ApplicantOverviewDetail,
  Application,
  AttemptApplicantDetail,
  ProductFeeResponse,
  QuestionnaireProduct,
} from "./med/types"
import {
  Address,
  ApplicationStatus,
  Certification,
  Evidence,
  LanguageProficiency,
  Profiles,
  Qualification,
  Question,
  SyncStatus,
  WorkExperience,
} from "./types"
import { urls } from "./urls"

export default {
  questionnaireProduct: {
    get: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<QuestionnaireProduct[]>> => {
      try {
        const res = await axios.get(urls.product.get(urlParams.slug))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  applicant: {
    overview: async (): Promise<APIResponse<ApplicantOverviewDetail[]>> => {
      try {
        const res = await axios.get(urls.overview.init())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<AttemptApplicantDetail>> => {
      try {
        const res = await axios.put(urls.applicant.detail(), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  application: {
    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Application>> => {
      try {
        const res = await axios.put(urls.attempt.details(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    create: async ({
      data,
    }: Config): Promise<APIResponse<{ status: ApplicationStatus }>> => {
      try {
        const res = await axios.post(urls.attempt.details(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    details: async ({ headers }: Config): Promise<APIResponse<Application>> => {
      try {
        const res = await axios.get(urls.attempt.details(), { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    getDecryptedFile: async ({
      headers,
      params,
    }: Config): Promise<{ data: Blob }> => {
      const res = await axios.get(urls.application.getDecryptedFile(), {
        responseType: "blob",
        headers,
        params,
      })

      return res
    },
  },
  attempt: {
    createResponse: async ({
      data,
      urlParams: { questionId },
      headers,
    }: ConfigWithURLParams): Promise<APIResponse<Question>> => {
      try {
        const res = await axios.post(
          urls.attempt.createResponse(questionId),
          data,
          { headers }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    validateStep: async ({
      params,
      headers,
    }: Config): Promise<APIResponse<Question>> => {
      try {
        const res = await axios.get(urls.attempt.validateStep(), {
          params,
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    submit: async ({
      data,
      headers,
    }: Config): Promise<
      APIResponse<{
        message: string
      }>
    > => {
      try {
        const res = await axios.put(urls.attempt.submit(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  address: {
    create: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Address>> => {
      try {
        const res = await axios.post(urls.address.create(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams: { addressId },
      headers,
    }: ConfigWithURLParams): Promise<APIResponse<Address>> => {
      try {
        const res = await axios.put(urls.address.update(addressId), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  payment: {
    getProductPrice: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams<"productId" | "centreId">): Promise<
      APIResponse<ProductFeeResponse>
    > => {
      try {
        const res = await axios.get(
          urls.payment.productPrice(urlParams.productId, urlParams.centreId),
          { headers, params }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  profile: {
    getProfile: async ({
      headers,
      params,
    }: Config): Promise<APIResponse<Profiles>> => {
      try {
        const res = await axios.get(urls.profiles.base(), { headers, params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    syncStatus: async ({
      headers,
    }: Config): Promise<APIResponse<SyncStatus>> => {
      try {
        const res = await axios.get(urls.profiles.syncStatus(), { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    languageProficiencies: {
      create: async ({
        data,
        headers,
      }: Config): Promise<APIResponse<LanguageProficiency>> => {
        try {
          const res = await axios.post(
            urls.languageProficiencies.create(),
            data,
            { headers }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams: { languageProficiencyId },
        params,
      }: ConfigWithURLParams<"languageProficiencyId">): Promise<
        APIResponse<LanguageProficiency>
      > => {
        try {
          const res = await axios.get(
            urls.languageProficiencies.base(languageProficiencyId),
            {
              params,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      update: async ({
        urlParams: { languageProficiencyId },
        data,
        headers,
      }: ConfigWithURLParams<"languageProficiencyId">): Promise<
        APIResponse<LanguageProficiency>
      > => {
        try {
          const res = await axios.put(
            urls.languageProficiencies.base(languageProficiencyId),
            data,
            { headers }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        urlParams: { languageProficiencyId },
        params,
        headers,
      }: ConfigWithURLParams<"languageProficiencyId">): Promise<
        APIResponse<LanguageProficiency>
      > => {
        try {
          const res = await axios.delete(
            urls.languageProficiencies.base(languageProficiencyId),
            {
              params,
              headers,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
    qualification: {
      create: async ({
        data,
        headers,
      }: Config): Promise<APIResponse<Qualification>> => {
        try {
          const res = await axios.post(urls.qualification.create(), data, {
            headers,
          })
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams: { qualificationId },
        params,
      }: ConfigWithURLParams<"qualificationId">): Promise<
        APIResponse<Qualification>
      > => {
        try {
          const res = await axios.get(
            urls.qualification.base(qualificationId),
            {
              params,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      update: async ({
        urlParams: { qualificationId },
        data,
        headers,
      }: ConfigWithURLParams<"qualificationId">): Promise<
        APIResponse<Qualification>
      > => {
        try {
          const res = await axios.put(
            urls.qualification.base(qualificationId),
            data,
            { headers }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        urlParams: { qualificationId },
        params,
        headers,
      }: ConfigWithURLParams<"qualificationId">): Promise<
        APIResponse<Qualification>
      > => {
        try {
          const res = await axios.delete(
            urls.qualification.base(qualificationId),
            {
              params,
              headers,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
    certification: {
      create: async ({
        data,
        headers,
      }: Config): Promise<APIResponse<Certification>> => {
        try {
          const res = await axios.post(urls.certification.create(), data, {
            headers,
          })
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams: { certificationId },
        params,
      }: ConfigWithURLParams<"certificationId">): Promise<
        APIResponse<Certification>
      > => {
        try {
          const res = await axios.get(
            urls.certification.base(certificationId),
            {
              params,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      update: async ({
        urlParams: { certificationId },
        data,
        headers,
      }: ConfigWithURLParams<"certificationId">): Promise<
        APIResponse<Certification>
      > => {
        try {
          const res = await axios.put(
            urls.certification.base(certificationId),
            data,
            { headers }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        urlParams: { certificationId },
        params,
        headers,
      }: ConfigWithURLParams<"certificationId">): Promise<
        APIResponse<Certification>
      > => {
        try {
          const res = await axios.delete(
            urls.certification.base(certificationId),
            {
              params,
              headers,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
    workExperiences: {
      create: async ({
        data,
        headers,
      }: Config): Promise<APIResponse<WorkExperience>> => {
        try {
          const res = await axios.post(urls.workExperiences.create(), data, {
            headers,
          })
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams: { workExperienceId },
        params,
      }: ConfigWithURLParams<"workExperienceId">): Promise<
        APIResponse<WorkExperience>
      > => {
        try {
          const res = await axios.get(
            urls.workExperiences.base(workExperienceId),
            {
              params,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      update: async ({
        urlParams: { workExperienceId },
        data,
        headers,
      }: ConfigWithURLParams<"workExperienceId">): Promise<
        APIResponse<WorkExperience>
      > => {
        try {
          const res = await axios.put(
            urls.workExperiences.base(workExperienceId),
            data,
            { headers }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        urlParams: { workExperienceId },
        params,
        headers,
      }: ConfigWithURLParams<"workExperienceId">): Promise<
        APIResponse<WorkExperience>
      > => {
        try {
          const res = await axios.delete(
            urls.workExperiences.base(workExperienceId),
            {
              params,
              headers,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
    certificates: {
      create: async ({
        data,
        headers,
      }: Config): Promise<APIResponse<Evidence>> => {
        try {
          const res = await axios.post(urls.certificates.base(), data, {
            headers,
          })
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        data,
        headers,
      }: Config): Promise<APIResponse<Evidence>> => {
        try {
          const res = await axios.delete(urls.certificates.base(), {
            data,
            headers,
          })
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
  },
}
