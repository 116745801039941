import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Country, CourseItem, QualificationItem } from "./types"
import { urls } from "./urls"

export default {
  submitNewsLetter: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(
        "https://hook.eu1.make.com/tgl33aez4nl3oo3ia2gd75csalgftj7y",
        data,
        { withCredentials: false }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getQualifications: async ({
    headers,
    params,
  }: Config): Promise<APIResponse<QualificationItem[]>> => {
    try {
      const res = await axios.get(urls.listQualifications(), {
        params,
        headers,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getCourses: async ({
    headers,
    params,
  }: Config): Promise<APIResponse<CourseItem[]>> => {
    try {
      const res = await axios.get(urls.listCourses(), {
        params,
        headers,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getCountry: async ({ headers }: Config): Promise<APIResponse<Country>> => {
    try {
      const res = await axios.get(urls.getCountry(), {
        headers,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
