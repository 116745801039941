import { AuthData } from "api/resources/users/types"
// eslint-disable-next-line import/no-cycle
import { getOtherPlatformAuthInfo } from "utils/auth"
import { importantSearchParams, Platforms, USER_TYPE } from "utils/constants"

export const getCurrentPlatform = (): Platforms => {
  if (typeof window === "undefined") return USER_TYPE

  const search = new URLSearchParams(window.location.search)
  const studentAuth = getOtherPlatformAuthInfo(Platforms.student)
  const counsellorAuth = getOtherPlatformAuthInfo(
    Platforms.applicationFormAdmin
  )

  const isCounsellor =
    search.get(importantSearchParams.platformOverride) ===
      Platforms.applicationFormAdmin && Boolean(counsellorAuth)

  if (isCounsellor) return Platforms.applicationFormAdmin

  const isStudent =
    search.get(importantSearchParams.platformOverride) === Platforms.student &&
    Boolean(studentAuth)

  if (isStudent) return Platforms.student

  return USER_TYPE
}

export const getCurrentAuth = (): AuthData | null => {
  const platform = getCurrentPlatform()

  switch (platform) {
    case Platforms.applicationFormAdmin: {
      return getOtherPlatformAuthInfo(platform) || null
    }
    case Platforms.student: {
      return getOtherPlatformAuthInfo(platform) || null
    }
    default:
      throw new Error("platform not handled")
  }
}
