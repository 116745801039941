import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getCygnusURL = getServiceURL(ServicePrefix.cygnus)

export const urls = validateUrls({
  get: slug => getCygnusURL(`/v1/masterclasses/${slug}/`),

  list: () => getCygnusURL("/v1/masterclasses/"),

  register: masterclassSlug =>
    getCygnusURL(`/v1/masterclasses/${masterclassSlug}/register/`),

  verifyRegistration: registrationId =>
    getCygnusURL(`/v1/masterclasses/registrations/${registrationId}/verify/`),

  getRegistrationId: masterclassSlug =>
    getCygnusURL(`/v1/masterclasses/${masterclassSlug}/registrations/`),

  getCertificate: registrationId =>
    getCygnusURL(`/v1/masterclasses/certificates/${registrationId}/`),
  downloadCertificate: certificateId =>
    getCygnusURL(`/v1/masterclasses/certificates/${certificateId}/download/`),
})
