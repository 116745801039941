import applicationForm from "./resources/applicationForm"
import auth from "./resources/auth"
import credentials from "./resources/credentials"
import global from "./resources/global"
import learn from "./resources/learn"
import masterclasses from "./resources/masterclasses"
import orders from "./resources/orders"
import payment from "./resources/payment"
import pgctl from "./resources/pgctl"
import registrationForm from "./resources/registrationForm"
import subscriptions from "./resources/subscriptions"
import ttm from "./resources/talkToAMentor"
import users from "./resources/users"

export default {
  registrationForm,
  credentials,
  users,
  pgctl,
  global,
  learn,
  auth,
  payment,
  masterclasses,
  applicationForm,
  ttm,
  subscriptions,
  orders,
}
