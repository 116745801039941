var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6bOI2MS1d0uPGir1DzK7E"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

import { getAuthInfo } from "utils/auth"
// Helpers

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

if (SENTRY_DSN) {
  Sentry.init({
    ignoreErrors: [
      // Not something that we can fix, doesn't affect the user anyway
      "Cancel rendering route",
      // Can't do anything about this
      "FirebaseError: Failed to get document because the client is offline.",
      // Errors in iOS Instagram WebView. Safe to ignore. https://developers.facebook.com/community/threads/320013549791141/
      "Can't find variable: _AutofillCallbackHandler",
      // Zoho script error
      "SyntaxError: JSON.parse: unexpected non-whitespace character after JSON data at line 1 column 50 of the JSON data",
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,

      // Webengage
      /p\.webengage\.com:443/i,

      // Facebook fluff
      /graph\.facebook\.com/i,
    ],
    dsn: SENTRY_DSN,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
  if (typeof window !== "undefined") {
    const authInfo = getAuthInfo()
    if (authInfo)
      Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
  }
}
