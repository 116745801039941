import { createContext } from "react"

import { Country } from "api/resources/global/types"
import { Registration } from "api/resources/registrationForm/types"

type Context = {
  isLoggedIn: boolean
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  currentCountry: Country | null | undefined
  setItoDetails: (data: Partial<Registration>) => void
  itoDetails: Registration | null | undefined
  refreshItoDetails: () => Promise<Registration | null>
}

export const GlobalContext = createContext<Context>({
  isLoggedIn: false,
  refreshItoDetails: () => new Promise(() => {}),
  setIsLoggedIn: () => {},
  setItoDetails: () => {},
  currentCountry: null,
  itoDetails: undefined,
})
