/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React from "react"

import { captureException } from "@sentry/react"

type Props = any
type State = any

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    captureException(new Error("Application Error!"), scope => {
      scope.setExtras({
        error,
        errorInfo,
      })
      return scope
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="flex flex-col items-center justify-center"
          style={{ minHeight: "100vh", textAlign: "center" }}
        >
          <h2 className="mb-1">Looks like an error occurred!</h2>
          <p>
            Don't worry. We're looking at this problem.
            <br />
            If the issue still persists after a few hours please reach out to{" "}
            <a href="mailto:care@suraasa.com">care@suraasa.com</a>.
          </p>
        </div>
      )
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary
