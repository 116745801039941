import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Reference } from "../applicationForm/types"

import { Application, AttemptApplicantDetail, ChangesList } from "./types"
import { urls } from "./urls"

export default {
  // PgCTL Specific due to types
  applicant: {
    detail: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<AttemptApplicantDetail>> => {
      try {
        const res = await axios.put(urls.applicant.detail(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({ data, headers }: Config): Promise<APIResponse<any>> => {
      try {
        const res = await axios.put(urls.applicant.update(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  // PgCTL Specific due to types
  application: {
    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Application>> => {
      try {
        const res = await axios.put(urls.attempt.start(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    details: async ({ headers }: Config): Promise<APIResponse<Application>> => {
      try {
        const res = await axios.get(urls.attempt.start(), { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  // PgCTL specific because M.Ed doesn't have references
  references: {
    create: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Reference>> => {
      try {
        const res = await axios.post(urls.references.create(), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams: { referenceId },
      params,
    }: ConfigWithURLParams<"referenceId">): Promise<APIResponse<Reference>> => {
      try {
        const res = await axios.get(urls.references.base(referenceId), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { referenceId },
      data,
      headers,
    }: ConfigWithURLParams<"referenceId">): Promise<APIResponse<Reference>> => {
      try {
        const res = await axios.put(urls.references.base(referenceId), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams: { referenceId },
      params,
      headers,
    }: ConfigWithURLParams<"referenceId">): Promise<APIResponse<Reference>> => {
      try {
        const res = await axios.delete(urls.references.base(referenceId), {
          params,
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  // PgCTL specific because M.Ed doesn't have reviewer side yet
  review: {
    update: async ({ data, headers }: Config): Promise<APIResponse<any>> => {
      try {
        const res = await axios.put(urls.review.update(), data, { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponse<ChangesList[]>> => {
      try {
        const res = await axios.get(urls.review.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  declaration: {
    uploadAttachment: async ({
      data,
      headers,
    }: Config): Promise<
      APIResponse<
        {
          applicationDeclaration: number
          attachment: string
          id: number
        }[]
      >
    > => {
      try {
        const res = await axios.post(
          urls.declaration.uploadAttachment(),
          data,
          {
            headers,
          }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
    deleteAttachment: async ({
      urlParams: { applicationDeclarationAttachmentId },
      headers,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.declaration.deleteAttachment(applicationDeclarationAttachmentId),
          {
            headers,
          }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
}
